import { Link } from "gatsby"
import React from "react"
import { Sticky } from "react-sticky"
import headerStyles from "./header.module.css"
import Logo from "../images/logo.png"
import Hamburger from "../images/hamburg.svg"
import Close from "../images/close.svg"
import MobileNavigation from "./mobile_navigation"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = { navCollapsed: false }
    this.toggleNav = this.toggleNav.bind(this)
  }

  toggleNav() {
    this.setState(state => ({ navCollapsed: !state.navCollapsed }))
  }

  render() {
    const { navCollapsed } = this.state
    return (
      <>
        <Sticky topOffset={30}>
          {({ style }) => (
            <header style={style} className={navCollapsed ? headerStyles.collapse : null}>
              <Link to="/" className={headerStyles.title}>
                <img src={Logo} alt="Thirty Madison" />
              </Link>
              <nav>
                <ul className={headerStyles.navLinks}>
                  <li>
                    <Link to="/#about-us">About us</Link>
                  </li>
                  <li>
                    <Link to="/careers">Careers</Link>
                  </li>
                  <li>
                    <Link to="/#contact-us">Contact us</Link>
                  </li>
                </ul>
                <button
                  type="button"
                  aria-expanded="false"
                  className={`${navCollapsed ? headerStyles.menu : null} ${headerStyles.navToggleIcon}`}
                  onClick={this.toggleNav}
                >
                  <img className={headerStyles.hamburger} src={Hamburger} alt="hamburger" />
                  <img className={headerStyles.closeIcon} src={Close} alt="close" />
                </button>
              </nav>
            </header>
          )}
        </Sticky>
        {navCollapsed && <MobileNavigation toggleNav={this.toggleNav} />}
      </>
    )
  }
}

Header.defaultProps = {
  siteTitle: ""
}

export default Header
