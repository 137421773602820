import { Link } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import Footer from "./footer"
import footerStyles from "./footer.module.css"
import mobileNavigationStyles from "./mobile_navigation.module.css"

class MobileNavigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = { displayNav: false }
    this.resize = this.resize.bind(this)
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize)
    this.resize()
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize)
  }

  resize() {
    // Only update state if needed
    const currentDisplayNav = window.innerWidth <= 1024
    if (currentDisplayNav !== this.state.displayNav) {
      this.setState({ displayNav: currentDisplayNav })
    }
  }

  render() {
    const { displayNav } = this.state
    if (!displayNav) {
      return null
    }
    return (
      <div className={mobileNavigationStyles.mobileMenu}>
        <div
          className={mobileNavigationStyles.navDropdown}
          onClick={this.props.toggleNav}
          onKeyDown={this.handleClick}
          role="presentation"
        >
          <Link to="/#about-us" className={mobileNavigationStyles.navLink}>
            <div>About us</div>
          </Link>
          <Link to="/careers" className={mobileNavigationStyles.navLink}>
            <div>Careers</div>
          </Link>
          <Link to="/#contact-us" className={mobileNavigationStyles.navLink}>
            <div>Contact us</div>
          </Link>
        </div>
        <Footer style={footerStyles.tabletNavContainer} />
      </div>
    )
  }
}

MobileNavigation.propTypes = {
  toggleNav: PropTypes.func.isRequired
}

export default MobileNavigation
