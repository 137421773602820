import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons"
import footerStyles from "./footer.module.css"

const SECURITY = "/security"

const Footer = ({ style }) => (
  <>
    <div className={`${footerStyles.container} ${style} `} id="footer">
      <div className={`${footerStyles.row} ${style} `} id="brands">
        <div>
          <a href="https://thirtymadison.com/" className={footerStyles.link} target="_top" rel="noopener noreferrer">
            Thirty Madison
          </a>
        </div>
        <div>
          <a href="https://www.keeps.com/" className={footerStyles.link} target="_top" rel="noopener noreferrer">
            Keeps
          </a>
        </div>
        <div>
          <a href="https://www.nurx.com/" className={footerStyles.link} target="_top" rel="noopener noreferrer">
            Nurx
          </a>
        </div>
        <div>
          <a href="https://www.withcove.com/" className={footerStyles.link} target="_top" rel="noopener noreferrer">
            Cove
          </a>
        </div>
        <div>
          <a href="https://evens.com/" className={footerStyles.link} target="_top" rel="noopener noreferrer">
            Evens
          </a>
        </div>
        <div>
          <a href="https://picnicallergy.com/" className={footerStyles.link} target="_top" rel="noopener noreferrer">
            Picnic
          </a>
        </div>
        <div>
          <a href="https://facetcare.com/" className={footerStyles.link} target="_top" rel="noopener noreferrer">
            Facet
          </a>
        </div>
      </div>
      <div className={`${footerStyles.row} ${style} `} id="contact-us">
        <div>
          <a href={SECURITY} className={footerStyles.link} target="_blank" rel="noopener noreferrer">
            Report a security vulnerability
          </a>
        </div>
        <div>
          <a
            href="mailto:press@thirtymadison.com"
            className={footerStyles.link}
            target="_top"
            rel="noopener noreferrer"
          >
            press@thirtymadison.com
          </a>
        </div>
        <div>
          <a
            href="mailto:careers@thirtymadison.com"
            className={footerStyles.link}
            target="_top"
            rel="noopener noreferrer"
          >
            careers@thirtymadison.com
          </a>
        </div>
        <div>
          <a
            href="mailto:inquiries@thirtymadison.com"
            className={footerStyles.link}
            target="_top"
            rel="noopener noreferrer"
          >
            inquiries@thirtymadison.com
          </a>
        </div>
        <div className={footerStyles.socialIcons}>
          <a href="https://www.linkedin.com/company/thirty-madison-inc./" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
        </div>
      </div>
    </div>
  </>
)

Footer.propTypes = {
  style: PropTypes.string
}

Footer.defaultProps = {
  style: ""
}

export default Footer
